<script setup>
const domain = "https://outsource.qrs.vn";
const route = useRoute();

useHead({
  link: [{ rel: "canonical", href: domain + route.path }],
  meta: [
    { name: "og:url", content: domain + route.path },
    { name: "og:type", content: "website" },
    { name: "og:image", content: domain + "/images/our-clients-bg.jpg" },
    { name: "twitter:card", content: "summary" },
    { name: "twitter:image", content: domain + "/images/our-clients-bg.jpg" },
  ],
});
</script>

<template>
  <Preloader />

  <DelayHydration>
    <div class="min-h-[100vh]">
      <PageHeader />

      <main class="page-content max-xl:pt-0 mt-[80px] max-xl:mt-[64px] overflow-hidden">
        <NuxtPage />
      </main>

      <PageFooter />
    </div>
  </DelayHydration>
</template>

<style lang="scss" scoped>
.heading-title,
.picture {
  :deep(img) {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
