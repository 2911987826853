import { default as chinh_45sach_45bao_45mat3hqSbyN9o8Meta } from "/home/runner/work/miraway-group/miraway-group/pages/chinh-sach-bao-mat.vue?macro=true";
import { default as dieu_45khoan_45su_45dungcroBbVIRhuMeta } from "/home/runner/work/miraway-group/miraway-group/pages/dieu-khoan-su-dung.vue?macro=true";
import { default as du_45anRp7wCKAkXQMeta } from "/home/runner/work/miraway-group/miraway-group/pages/du-an.vue?macro=true";
import { default as giai_45phap285k3CQEySMeta } from "/home/runner/work/miraway-group/miraway-group/pages/giai-phap.vue?macro=true";
import { default as indexhSyn3ozjqYMeta } from "/home/runner/work/miraway-group/miraway-group/pages/index.vue?macro=true";
import { default as lien_45he21utfEljqRMeta } from "/home/runner/work/miraway-group/miraway-group/pages/lien-he.vue?macro=true";
import { default as linh_45vuc_45hoat_45dong7Oq5NGL2CeMeta } from "/home/runner/work/miraway-group/miraway-group/pages/linh-vuc-hoat-dong.vue?macro=true";
import { default as tuyen_45dungby04DjHfKAMeta } from "/home/runner/work/miraway-group/miraway-group/pages/tuyen-dung.vue?macro=true";
import { default as ve_45chung_45toiavtnmIjuCNMeta } from "/home/runner/work/miraway-group/miraway-group/pages/ve-chung-toi.vue?macro=true";
export default [
  {
    name: "chinh-sach-bao-mat",
    path: "/chinh-sach-bao-mat",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/chinh-sach-bao-mat.vue").then(m => m.default || m)
  },
  {
    name: "dieu-khoan-su-dung",
    path: "/dieu-khoan-su-dung",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/dieu-khoan-su-dung.vue").then(m => m.default || m)
  },
  {
    name: "du-an",
    path: "/du-an",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/du-an.vue").then(m => m.default || m)
  },
  {
    name: "giai-phap",
    path: "/giai-phap",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/giai-phap.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lien-he",
    path: "/lien-he",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/lien-he.vue").then(m => m.default || m)
  },
  {
    name: "linh-vuc-hoat-dong",
    path: "/linh-vuc-hoat-dong",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/linh-vuc-hoat-dong.vue").then(m => m.default || m)
  },
  {
    name: "tuyen-dung",
    path: "/tuyen-dung",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/tuyen-dung.vue").then(m => m.default || m)
  },
  {
    name: "ve-chung-toi",
    path: "/ve-chung-toi",
    component: () => import("/home/runner/work/miraway-group/miraway-group/pages/ve-chung-toi.vue").then(m => m.default || m)
  }
]