export function scrollToTitle(title: string) {
  setTimeout(() => {
    const targetElement = document.querySelector(`*[id='${title.toLowerCase()}']`);
    if (targetElement) {
      const yOffset = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const headerOffset = 310;
      window.scrollTo({ top: yOffset - headerOffset, behavior: "smooth" });
    }
  }, 100);
}
