<template>
  <footer class="bg-[url(/images/footer-bg.png)] bg-center max-lg:bg-fixed">
    <!-- Đoạn văn bản hiển thị trên desktop -->
    <div class="hidden lg:block">
      <nuxt-picture
        loading="lazy"
        src="/images/logo-white.svg"
        :img-attrs="{ alt: 'logo' }"
        width="274px"
        height="69px"
      />
    </div>
    <!-- Đoạn văn bản hiển thị trên mobile -->
    <div class="block lg:hidden">
      <nuxt-picture
        loading="lazy"
        src="/images/logo-white.svg"
        :img-attrs="{ alt: 'logo' }"
        width="164px"
        height="42px"
      />
    </div>

    <div class="flex max-lg:flex-wrap gap-[40px] justify-between mt-2">
      <div>
        <div id="company-info" class="max-lg:w-full">
          <div class="Compay-name foot-1-content mb-6 max-md:text-[12px] max-md:font-[700]">
            CÔNG TY CỔ PHẦN MIRAWAY GIẢI PHÁP CÔNG NGHỆ
          </div>

          <div id="HN-address">
            <div class="max-w-[496px] flex gap-2 items-center">
              <div class="foot-2-content max-md:text-[12px] max-md:font-[500]">
                VP Tại Hà Nội: Tầng 4 - Tòa nhà GTC, Số 69, phố Tố Hữu, Vạn Phúc, Hà Đông, Hà Nội
              </div>
            </div>
          </div>

          <div id="HCM-address" class="mt-3">
            <div class="max-w-[496px] flex gap-2 items-center">
              <div class="foot-2-content max-md:text-[12px] max-md:font-[500]">
                VP Tại HCM: Số 211, đường 9A Trung Sơn, Bình Hưng, Bình Chánh, TP Hồ Chí Minh
              </div>
            </div>
          </div>

          <div id="phone-number" class="flex gap-2 items-center mt-3">
            <div class="foot-2-content max-md:text-[12px] max-md:font-[500]">Tel: +84 24 36 436 999</div>
          </div>

          <div id="phone-number" class="flex gap-2 items-center mt-3">
            <div class="foot-2-content max-md:text-[12px] max-md:font-[500]">Hotline tư vấn 24/7: 0981 350 960</div>
          </div>

          <div id="email" class="flex gap-2 items-center mt-3">
            <div class="foot-2-content max-md:text-[12px] max-md:font-[500]">Email: contact@miraway.vn</div>
          </div>
        </div>
      </div>
      <div id="product" class="flex flex-col gap-y-6 max-lg:w-1/3 max-lg:flex-1">
        <div class="foot-1-content uppercase max-md:text-[16px] max-md:font-[700]">Giới thiệu Miraway</div>
        <nuxt-link to="/giai-phap" class="foot-2-content max-md:text-[16px] max-md:font-[700]">Giải pháp</nuxt-link>
        <nuxt-link to="https://miraway.vn/san-pham" class="foot-2-content max-md:text-[16px] max-md:font-[700]">Sản phẩm</nuxt-link>
        <nuxt-link to="/du-an" class="foot-2-content max-md:text-[16px] max-md:font-[700]">Dự án</nuxt-link>
        <nuxt-link to="https://mirawaygroup.com/blog" class="foot-2-content max-md:text-[16px] max-md:font-[700]"
          >Tin tức và sự kiện</nuxt-link
        >
        <nuxt-link to="/tuyen-dung" class="foot-2-content max-md:text-[16px] max-md:font-[700]">Tuyển dụng</nuxt-link>
      </div>

      <div id="QRX" class="flex flex-col gap-y-6 max-lg:w-1/3 max-lg:flex-1">
        <div class="foot-1-content uppercase max-md:text-[16px] max-md:font-[700]">Liên hệ</div>

        <nuxt-link to="https://mirawaygroup.com/blog/chinh-sach/" class="foot-2-content max-md:text-[16px] max-md:font-[700]">Chính sách</nuxt-link>
        <nuxt-link to="https://mirawaygroup.com/blog/dieu-khoan-su-dung/" class="foot-2-content max-md:text-[16px] max-md:font-[700]">Hướng dẫn</nuxt-link>
      </div>

      <div id="follow-us" class="flex flex-col gap-y-6 max-lg:w-1/3 max-lg:flex-1">
        <div class="foot-1-content uppercase max-md:text-[16px] max-md:font-[700]">Mạng xã hội</div>
        <!-- Đoạn văn bản hiển thị trên desktop -->
        <div class="hidden lg:block">
          <div class="flex flex-wrap gap-2">
            <nuxt-link
              to="https://www.facebook.com/Technology.Solution.Miraway/"
              target="_blank"
              class="cursor-pointer w-[42px] h-[42px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'facebook' }" loading="lazy" src="/images/socials/faceboock-white.svg" />
            </nuxt-link>

            <nuxt-link
              to="https://www.youtube.com/@miraway3008"
              target="_blank"
              class="cursor-pointer w-[42px] h-[42px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'twiter' }" loading="lazy" src="/images/socials/youtube.svg" />
            </nuxt-link>

            <nuxt-link
              to="https://www.linkedin.com/in/miraway-jsc-3608a5207/"
              target="_blank"
              class="cursor-pointer w-[42px] h-[42px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'linkerin' }" loading="lazy" src="/images/socials/linkerin-white.svg" />
            </nuxt-link>

            <nuxt-link
              to="https://zalo.me/4421520053658514179"
              target="_blank"
              class="cursor-pointer w-[42px] h-[42px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'linkerin' }" loading="lazy" src="/images/socials/zalo.svg" />
            </nuxt-link>
          </div>
        </div>

        <!-- Đoạn văn bản hiển thị trên mobile -->
        <div class="block lg:hidden">
          <div class="flex flex-wrap gap-2">
            <nuxt-link
              to="https://www.facebook.com/Technology.Solution.Miraway/"
              target="_blank"
              class="cursor-pointer w-[32px] h-[32px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'facebook' }" loading="lazy" src="/images/socials/faceboock-white.svg" />
            </nuxt-link>

            <nuxt-link
              to="https://www.youtube.com/@miraway3008"
              target="_blank"
              class="cursor-pointer w-[32px] h-[32px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'twiter' }" loading="lazy" src="/images/socials/youtube.svg" />
            </nuxt-link>

            <nuxt-link
              to="https://www.linkedin.com/in/miraway-jsc-3608a5207/"
              target="_blank"
              class="cursor-pointer w-[32px] h-[32px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'linkerin' }" loading="lazy" src="/images/socials/linkerin-white.svg" />
            </nuxt-link>

            <nuxt-link
              to="https://zalo.me/4421520053658514179"
              target="_blank"
              class="cursor-pointer w-[32px] h-[32px] border border-white rounded-[50%] flex justify-center items-center"
            >
              <nuxt-picture :img-attrs="{ alt: 'linkerin' }" loading="lazy" src="/images/socials/zalo.svg" />
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between py-4 mt-20">
      <small class="flex-1 text-[#EEEEEE] text-left">© Copyright – 2024 | MIRAWAY | All Rights Reserved</small>
    </div>
  </footer>
</template>
