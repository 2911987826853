<script setup>
const router = useRouter();

router.beforeResolve(() => {
  startAnimate();
});

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 100;

const percentage = ref(MIN_PERCENTAGE);
const isShow = ref(true);

const animatePercentage = (from, to) => {
  if (from >= to) return;

  from++;
  setTimeout(() => {
    percentage.value = from;
    animatePercentage(from, MAX_PERCENTAGE);
  }, 10);

  if (from === MAX_PERCENTAGE) {
    loadFinish();
  }
};

const loadFinish = () => {
  isShow.value = false;
};

const startAnimate = () => {
  isShow.value = true;
  percentage.value = MIN_PERCENTAGE;
  animatePercentage(MIN_PERCENTAGE, MAX_PERCENTAGE);
};

startAnimate();
</script>

<template>
  <div id="royal_preloader" class="royal_preloader_logo" :class="{ hidden: !isShow }">
    <div class="royal_preloader_loader">
      <div :style="{ bottom: `${percentage}%` }"></div>
    </div>
    <div class="royal_preloader_percentage">{{ `${percentage}%` }}</div>
  </div>
</template>
